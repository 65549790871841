import React from "react";
import SEO from "../components/seo.js";
import { gmailPubKey, protonPubKey } from "../components/keys.js";
import "../styles/index.scss";
import { Typography, Row, Col, Card, Divider, Button } from "antd";
import { GithubOutlined } from "@ant-design/icons";
import PageTransition from "gatsby-plugin-page-transitions";
import "antd/es/card/style/index.css";
import "antd/es/divider/style/index.css";
import "antd/es/button/style/index.css";
import "antd/es/row/style/css.js";
import "antd/es/col/style/css.js";
import "antd/es/typography/style/index.css";

const { Title, Paragraph, Text, Link } = Typography;

export default function IndexPage() {
  return (
    <PageTransition>
      <SEO title="home" />
      <div className="page">
        <Card>
          <Row justify="center" align="middle">
            <Col flex="auto">
              <div className="main_title">
                <Title>Brandon LaNuevo</Title>
              </div>
            </Col>
            <Col flex="20px" align="middle">
              <div className="links">
                <Button
                  type="link"
                  href="https://github.com/BrandonLaNuevo"
                  icon={<GithubOutlined />}
                ></Button>
              </div>
            </Col>
          </Row>
          <Row justify ="center" align="middle">
            <Paragraph>
                brandonlanuevo [<Text italic>at</Text>] gmail.com
                  <Divider type="vertical"></Divider>
                brandonlanuevo [<Text italic>at</Text>] protonmail.ch
            </Paragraph>
          </Row>
          <Row justify="center" align="middle">
            <Col span={24}>
              <div className="keys">
                <Title level={5}>Keys</Title>
              </div>
              <Card type="inner" size="small">
                <Row gutter={10}>
                  <Col span={12}>
                    <Card
                      type="inner"
                      size="small"
                      style={{ width: 200, height: 85 }}
                      title={
                        <Paragraph ellipsis={{ rows: 1, expandable: false }}>
                          <Text type="secondary" italic>
                            {gmailPubKey}
                          </Text>
                        </Paragraph>
                      }
                    >
                      <Paragraph
                        copyable={{ text: gmailPubKey, tooltips: false }}
                      >
                        <Text type="secondary" italic>
                          ed25519 Gmail
                        </Text>
                      </Paragraph>
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      type="inner"
                      size="small"
                      style={{ width: 200, height: 85 }}
                      title={
                        <Paragraph ellipsis={{ rows: 1, expandable: false }}>
                          <Text type="secondary" italic>
                            {protonPubKey}
                          </Text>
                        </Paragraph>
                      }
                    >
                      <Paragraph
                        copyable={{ text: protonPubKey, tooltips: false }}
                      >
                        <Text type="secondary" italic>
                          ed25519 Protonmail
                        </Text>
                      </Paragraph>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
    </PageTransition>
  );
}
