export const gmailPubKey = `
-----BEGIN PGP PUBLIC KEY BLOCK-----

mDMEX2DyqBYJKwYBBAHaRw8BAQdA529g2YSDCmgXNE3rtoFZ7fHTpEGYIfZAjnUV
av914Cq0KkJyYW5kb24gTGFOdWV2byA8YnJhbmRvbmxhbnVldm9AZ21haWwuY29t
PoiQBBMWCAA4FiEERa+RLrY7LBza0j00fvTYQf7YXggFAl9g8qgCGwMFCwkIBwIG
FQoJCAsCBBYCAwECHgECF4AACgkQfvTYQf7YXggf8gEAwi8utVNSVOBuZ9qIxjB8
gx9X4lisNIjo6vEt3TFREPoBAMRy3kTdI1QbkLtzKws1I1Nz/f1VkfCIDY+M48/Q
glYJ
=B0BB
-----END PGP PUBLIC KEY BLOCK-----
`;
export const protonPubKey = `
-----BEGIN PGP PUBLIC KEY BLOCK-----

mDMEX2DpyhYJKwYBBAHaRw8BAQdAx06/9+x6hISq878xCg4l9CPCUTwEFM+wx1YE
3Xukpgq0LkJyYW5kb24gTGFOdWV2byA8YnJhbmRvbmxhbnVldm9AcHJvdG9ubWFp
bC5jaD6IkAQTFggAOBYhBFQVFk6pzvZSJQwJqSvNid/WfQ+LBQJfYOnKAhsDBQsJ
CAcCBhUKCQgLAgQWAgMBAh4BAheAAAoJECvNid/WfQ+LEAoBALvOY80sC85PcjoL
07DVI1+/DsMjziBdZlMS/PUz9/dCAQDuj8RbMH5KU74g0qJY9DNy43KHUMujfHPO
kOb581hyBg==
=kkyL
-----END PGP PUBLIC KEY BLOCK-----
`;
